import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  getAuth,
  Auth,
} from "firebase/auth";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { firebaseConfigs } from "../config/firebase";
import { signal, Signal, effect } from "@preact/signals-react";

export const confirmationResult: Signal<ConfirmationResult | null> =
  signal(null);

export const recaptcaVerifier: Signal<RecaptchaVerifier | null> = signal(null);

export const auth: Signal<Auth | null> = signal(null);

export const firebaseToken: Signal<string | undefined> = signal(undefined);

const initFirebaseService = (firebaseConfig: FirebaseOptions) => {
  initializeApp(firebaseConfig);
  auth.value = getAuth();
};

effect(() => {
  initFirebaseService(firebaseConfigs["eu-prod"]);
});

export const getRecaptchaVerifier = (
  containerOrId: string | HTMLElement = "sign-in-button"
) => {
  recaptcaVerifier.value = new RecaptchaVerifier(getAuth(), containerOrId, {
    size: "invisible",
    callback: (response: any) => { },
  });

  return recaptcaVerifier.value;
};

export const clearRecaptchaVerifier = () => {
  if (!recaptcaVerifier) return;

  recaptcaVerifier.value?.clear();
  recaptcaVerifier.value = null;
};

export const requestPhoneAuthCode = async (phone: string) => {
  return (confirmationResult.value = await signInWithPhoneNumber(
    getAuth(),
    phone,
    getRecaptchaVerifier() as RecaptchaVerifier
  ));
};

export const submitPhoneVerificationCodeToFirebase = async (
  phoneCode: string
) => {
  const userCreds = await confirmationResult.value?.confirm(phoneCode);
  const token = await userCreds?.user.getIdToken(true);
  firebaseToken.value = token;
  return token;
};



export const refreshFirebaseToken: () => Promise<string> = () => {
  const a: any = getAuth();

  return new Promise((res, rej) => {
    a.currentUser.getIdToken({ forceRefresh: true })
      .then(function (idToken: string) {
        res(idToken as string);
      }).catch(rej);

  })
}

export const logoutFromFirebase = async () => {
  auth.value && (await auth.value.signOut());
};

export const initRecaptchaVerifier = (
  containerOrId: string | HTMLElement = "sign-in-button"
) => {
  clearRecaptchaVerifier();
  getRecaptchaVerifier(containerOrId);
};



export const resetRecaptchaVerifier = (name: string = "recaptcha-container") => {
  const win = window as any;

  win.recaptchaVerifier?.recaptcha?.reset();
  (win.recaptchaVerifier as any)?._reset()
  const rc = document.getElementById(name);
  if (rc)
    rc.parentElement?.removeChild(rc);
  const nrc = document.createElement('div')
  nrc.id = name;
  document.body.appendChild(nrc);


  if (!win.recaptchaVerifier) {
    const button = document.getElementById(name);
    if (button !== undefined && button !== null) {
      const verifier = new RecaptchaVerifier(getAuth(), name, {
        size: 'invisible',
        /*
        Motivation for the code:
        1. https://github.com/firebase/firebase-js-sdk/issues/3356
        2. https://github.com/jsardev/reaptcha/issues/218
        3. https://github.com/dozoisch/react-google-recaptcha/issues/129

        Cannot read property 'style' of null was the error message, and to get/test this error do the following steps:
        1. Enter a number, and an invalid number ideally
        2. Keep trying to submit till you see the captcha of image selector where it asks you to select buses, etc
        3. Once you click on Verify, it will show an error screen, and in some cases even when number was valid.
        
        Since the error was not due to any other code in this file, I decided to reset the captcha in each type of callback, and this seems to have solved the error
        */
        callback: () => {
          win.recaptchaVerifier.recaptcha.reset();
        },
        'expired-callback': () => {
          win.recaptchaVerifier.recaptcha.reset();
        },
        'error-callback': () => {
          win.recaptchaVerifier.recaptcha.reset();
        },
      });
      win.recaptchaVerifier = verifier;
    }
  }
}