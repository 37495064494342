import { env } from "./env";

export type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

export const firebaseConfigs: Record<string, FirebaseConfig> = {
  "eu-prod": {
    apiKey: env.REACT_APP_PROD_FIREBASE_API_KEY || "",
    authDomain: env.REACT_APP_PROD_FIREBASE_API_KEY || "",
    projectId: "newlifeio-prod",
    storageBucket: "newlifeio-prod.appspot.com",
    messagingSenderId: env.REACT_APP_PROD_FIREBASE_API_KEY || "",
    appId: env.REACT_APP_PROD_FIREBASE_APP_ID || "",
    measurementId: "G-YMT320RGLJ",
  },
};
