import { authDomains } from "./authDomains";
import { canonicalHosts, ensureProtocolAndHost } from "./hosts";
import { mediaBuckets } from "./mediaBuckets";
import { newgraphBaseUrls } from "./newgraphApi";
import { stage } from "./stages";
import { websocketsServers } from "./websocketsServers";

export const newgraphBaseUrl = newgraphBaseUrls[stage];
export const mediaBucket = mediaBuckets[stage];
export const websocketsServer = websocketsServers[stage];
export const authDomain = authDomains[stage];

export { stage };

ensureProtocolAndHost(canonicalHosts, stage)

// export const stripeKey = stripeKeys[stage];
