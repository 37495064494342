import { MoodReadResponse, PostReadResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { EnrichedPost, newgraphClient, PostUploadState } from "../..";
import { cachePosts } from "../post";

export function _uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
            +c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
        ).toString(16)
    );
}

export function uuidv4() {
    let i = 3;
    while (i--) {
        const uuid = _uuidv4();
        if (uuid)
            return uuid;
    }
    throw new Error("Couldn't generate uuid")
}


export const retry = async <T>(f: () => Promise<T>) => {
    const retries = [300, 600, 900, 1500];
    for (const n of retries) {
        try {
            const r = await f();
            return r;
        } catch (e) {
            console.log("Sleeping " + n);
            await new Promise(res => setTimeout(res, n));
            console.log("Retrying");
        }
    }

    throw new Error(`Failed after ${retries.length} retries`);
}

export const wait = (ms: number) => new Promise((res: any) => setTimeout(res, ms));


export const updateUploadStatus = async (status: PostUploadState["status"], post: EnrichedPost, folders?: MoodReadResponse[]) => {
    post.uploadState = { ...post.uploadState!, status };
    post.updated = new Date().toISOString();
  
    await cachePosts({ ...post }, folders || post.moods );
  }
  

  // const stage6_completeJobQueue = () => {
  //   const doNextJob = () => {
  //     uploadQueueSignal.value = [...uploadQueueSignal.value.slice(1)];
  
  //     const nextJob = uploadQueueSignal.value[0];
  //     nextJob && uploader.value.exec(nextJob);
  //   };
  
  //   setTimeout(doNextJob);
  // };
  

// const purePost = (EnrichedPost)