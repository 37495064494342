import { MoodReadResponse, PostReadResponse, UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import Dexie from "dexie";
import { castArray } from "lodash";

// Dexie.delete('iosdk-cache');

// type IGraphObject = { id: string };
type IGraphEdge = {
    id?: string;
    fromLabel?: string;
    toLabel?: string;
    from?: string;
    to?: string;
    value?: any;
    label: string;
    props?: any;
};
type IGraphEdgeEntry = IGraphEdge & {
    cached: string;
    __outE: string;
    __inE: string;
}

const EDGES_TABLE_NAME = "__EDGES";

const makeEdgeEntry = ({ id, fromLabel, toLabel, label, from, to, props }: IGraphEdge) => {
    const __outE = [fromLabel, from, label, toLabel, to].join("+");
    const __inE = [toLabel, to, label, fromLabel, from].join("+");

    return { 
        __outE, 
        __inE,
        label,
        fromLabel,
        from: from,
        toLabel,
        to: to,
        cached: new Date().toISOString(),
        id: id || __outE,
        props
    };
}
class _Store extends Dexie {
    [EDGES_TABLE_NAME]!: Dexie.Table<IGraphEdgeEntry, number>;

    constructor(name: string = "iosdk-cache", stores: Record<string, string>) {
        super(name);

        //
        // Define tables and indexes
        // (Here's where the implicit table props are dynamically created)
        //
        this.version(1).stores({
            ...stores,
            [EDGES_TABLE_NAME]: "++id,__outE,__inE,updated",
        });
    }

   
    async storeEdges(edges: IGraphEdge[] | IGraphEdge) {
        try {
            edges = castArray(edges);
            return this[EDGES_TABLE_NAME].bulkPut(edges.map(makeEdgeEntry));    
        } catch (ex) {
            throw ex;
        }
    }
    async deleteEdges(edges: number | number[]) {
        edges = castArray(edges);
        await this[EDGES_TABLE_NAME].where('id').anyOf(edges).delete();
    }
    storeEdge({ id, fromLabel, toLabel, label, from, to, props }: IGraphEdge) {
        if (!from || !to) {
            console.warn("cache2: attempted to cache a bad edge, skipping");
            return;
        }

        return this[EDGES_TABLE_NAME].put(makeEdgeEntry({ id, fromLabel, toLabel, label, from, to, props }))
    };
}

export const Store = <T>(name: string = "iosdk-cache", stores: Record<string, string>) => {
    return (new _Store(name, stores)) as (T & (_Store));
}