import { env } from "./env";
import { currentHost } from "./hosts";

const stageDomainsFromList = (list: string, stage: string) =>
    (list || "").split(/,/).reduce((r, c, o) => ({ ...r, [c]: stage }), {});

const stages: Record<string, string> = {
    ...stageDomainsFromList(env.REACT_APP_IOSDK_APP_DEV_HOST || "", "eu-dev"),
    ...stageDomainsFromList(env.REACT_APP_IOSDK_APP_SIT_HOST || "", "eu-sit"),
    ...stageDomainsFromList(env.REACT_APP_IOSDK_APP_PROD_HOST || "", "eu-prod"),
    // ...stageDomainsFromList(env.REACT_APP_IOSDK_APP_HOST || "", "eu-prod"),
};

export const stage = stages[currentHost];
