
const defaults = {
  width: 180,
  height: 180
}
export const resizeImage: (src: Blob) => Promise<string> = (src: Blob) => {
    const img = document.createElement('img');
    const canvas = document.createElement('canvas');

    const w = defaults.width;
    const h = defaults.height;

    const blobUrl = URL.createObjectURL(src) // blob is the Blob object

    img.src = blobUrl;
    return new Promise((resolve, reject) =>
      img.addEventListener("load", () => {
        
        const iw = img.width;
        const ih = img.height;

        const maxRatio = Math.max(iw/w, ih/h);

        canvas.width = iw / maxRatio;
        canvas.height = ih / maxRatio;

        return createImageBitmap(img, { resizeWidth: canvas.width, resizeHeight: canvas.height, resizeQuality: 'high' })
          .then(imageBitmap =>
            canvas.getContext('2d')!.drawImage(imageBitmap, 0, 0)
          )
          .then(() =>
            resolve(canvas.toDataURL())
          )
          .catch((err) => {
            reject(err)
          })
      })
    )
  }
  